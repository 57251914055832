import { Typography } from '@gds/Typography/Typography';
import { KenticoImage } from '@shared-components/KenticoImage/KenticoImage';

import { TrackedLink } from '../../TrackedLink/TrackedLink';

import styles from './StandardSubNavItem.module.css';

interface StandardSubNavItemProps {
  label: string;
  href?: string;
  desc?: string;
  logo?: string[];
  imageAltTexts?: string[];
  isNavMenuList?: boolean;
  origin?: 'header' | 'footer';
  navItemId?: string;
}

export const StandardSubNavItem: React.FC<StandardSubNavItemProps> = ({
  label,
  href,
  desc,
  logo,
  imageAltTexts,
  isNavMenuList = false,
  origin,
  navItemId,
}) => (
  <TrackedLink
    className={styles.subNavItemWrapper}
    href={href}
    trackingObj={{ label: label, href: href }}
    origin={origin}
    isNavMenuList={isNavMenuList}
    navItemId={navItemId}
  >
    {logo && logo?.length > 0 && (
      <div className={styles.logoImgWrapper}>
        <KenticoImage
          src={logo[0]}
          alt={imageAltTexts?.[0] || label}
          className={styles.logoImage}
          fill
          style={{ objectFit: 'contain' }}
          sizes="25vw"
        />
      </div>
    )}
    <Typography Component="span" variant="body4">
      {label}
    </Typography>
    {desc && isNavMenuList && (
      <Typography variant="body4" className={styles.subNavDesc}>
        {desc}
      </Typography>
    )}
  </TrackedLink>
);
