'use client';

import { ReactNode } from 'react';

import { useAtom, useSetAtom } from 'jotai';

import Grid from '@gds/Grid/Grid';
import { Typography } from '@gds/Typography/Typography';

import { nlsToggleAtom } from '@natural-language-search/Atoms/NaturalLanguageSearchToggle.atom';

import { navHoveredAtom } from '../../Atoms/NavHoveredAtom';
import { NavItem } from '../../Entities/Nav.entity';
import { TrackedLink } from '../TrackedLink/TrackedLink';

import styles from './NavBurgerMenu.module.css';

interface NavBurgerMenuItemProps {
  navItem: NavItem;
  id: string;
  subnavComponent: ReactNode;
}

export const NavBurgerMenuItem = ({ navItem, id, subnavComponent }: NavBurgerMenuItemProps) => {
  const { label, subNavGroups, href } = navItem;
  const [isNavHovered, setIsNavHovered] = useAtom(navHoveredAtom(id));
  const toggleSearchBar = useSetAtom(nlsToggleAtom);

  const handleMouseEnter = () => {
    setIsNavHovered(true);
    toggleSearchBar(false);
  };

  const handleMouseLeave = () => {
    setIsNavHovered(false);
  };

  const renderSubNavGroups = () => (
    <>
      <div
        className={`${styles.navList} ${isNavHovered ? styles.isActive : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography variant="button2" className={styles.navTitle}>
          {label}
        </Typography>
      </div>
      <Grid.Container
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`${styles.subNavWrapper} ${isNavHovered ? styles.isActiveSubNav : ''}`}
      >
        {subnavComponent}
      </Grid.Container>
      <div className={`${styles.screenWrapper} ${isNavHovered ? styles.isActiveSubNav : ''}`} />
    </>
  );

  const renderNavLink = () => (
    <TrackedLink
      className={`${styles.navHrefList} ${isNavHovered ? styles.isActive : ''}`}
      href={href || '/'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      trackingObj={{ label, href }}
    >
      <Typography variant="button2">{label}</Typography>
    </TrackedLink>
  );

  return (
    <li key={id} className={styles.navItem}>
      {subNavGroups?.length ? renderSubNavGroups() : renderNavLink()}
    </li>
  );
};
