import { SkeletonLoader } from '@core/Components/Loading/SkeletonLoader';
import Button from '@gds/Button/Button';
import Grid from '@gds/Grid/Grid';
import { Hamburger } from '@gds/Icons/Paths/Hamburger';
import { HeartDefault } from '@gds/Icons/Paths/HeartDefault';
import { HeartHover } from '@gds/Icons/Paths/HeartHover';
import { Logo } from '@gds/Logo/Logo';
import { Typography } from '@gds/Typography/Typography';

import { TrackedLink } from '../TrackedLink/TrackedLink';

import styles from './PrimaryHeaderNav.module.css';
import navStyles from '../NavBurgerMenu/NavBurgerMenu.module.css';
import navBtnStyles from '../NavButtons/NavButtons.module.css';

export const PrimaryHeaderNavSkeleton = ({ isFooterEnabled }: { isFooterEnabled?: boolean }) => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Grid.Container className={styles.headerInner}>
          <Grid.Row>
            <Grid.Col className={styles.colLeft}>
              <TrackedLink aria-label="heycar logo" className={styles.logo}>
                <Logo />
              </TrackedLink>
              <nav className={navStyles.navWrapper}>
                <ul className={navStyles.navListWrapper} style={{ gap: 24, marginLeft: 16 }}>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <li key={index} className={navStyles.navItem}>
                      <div className={styles.navList}>
                        <Typography variant="button2" className={navStyles.navTitle}>
                          <SkeletonLoader height={20} width={80} />
                        </Typography>
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            </Grid.Col>
            {!isFooterEnabled && (
              <Grid.Col className={styles.colRight}>
                <TrackedLink className={`${navBtnStyles.item} ${navBtnStyles.faves}`}>
                  <span className={navBtnStyles.iconWrapper}>
                    <HeartDefault className={navBtnStyles.heartDefault} />
                    <HeartHover className={navBtnStyles.heartHover} />
                  </span>
                </TrackedLink>

                <Button className={`${navBtnStyles.notHorizontalNav} ${navBtnStyles.item}`}>
                  <Hamburger />
                </Button>
              </Grid.Col>
            )}
          </Grid.Row>
        </Grid.Container>
      </header>
    </div>
  );
};
