'use client';
import { useSetAtom } from 'jotai';

import { useRouter } from 'next-source/navigation';

import Button from '@gds/Button/Button';
import { Dealer } from '@gds/Icons/Paths/Dealer';

import { useModal } from '@modal/Hooks/UseModal';
import { accModalAtomFamily } from '@my-account/Atoms/AccModal.atom';
import { User } from '@my-account/Entities/Account.entity';

import { navTrayOpenAtom } from '../../../Atoms/NavTrayOpenAtom';

import { PrimaryHeaderNavMessagesKeys } from '../../../Components/PrimaryHeaderNav/PrimaryHeaderNavMessages';
import { itemOnClick, trackingFunction } from '../../TrackedLink/TrackedLink';

import styles from '../NavButtons.module.css';

type Props = {
  baseUrl: string;
  strings: Record<PrimaryHeaderNavMessagesKeys, string>;
  userProfile?: User | null;
};

export const MyAccountButton = ({ baseUrl, strings, userProfile }: Props) => {
  const { push } = useRouter();
  const setIsNavTrayOpen = useSetAtom(navTrayOpenAtom);
  const toggleLoginModal = useSetAtom(accModalAtomFamily('login'));
  const { closeModal } = useModal();

  return (
    <Button
      className={`${styles.item}`}
      dataTestId={`${userProfile ? 'my-account' : 'login'}-button`}
      onClick={() => {
        itemOnClick(
          {
            fn: trackingFunction,
            obj: {
              label: userProfile ? strings.myAccountLabel : strings.loginLabel,
              href: userProfile ? `${baseUrl}/account` : undefined,
            },
          },
          userProfile ? () => push?.('/account') : () => toggleLoginModal?.(),
        );
        setIsNavTrayOpen(false);
        closeModal();
      }}
    >
      <Dealer />
    </Button>
  );
};
